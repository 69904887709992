<template>
  <div data-aos="fade-up" data-aos-duration="600">
    <div class="wpo-breadcumb-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wpo-breadcumb-wrap">
              <h2>Our Events</h2>
              <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><span>Event</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- .wpo-breadcumb-area end -->

    <!-- wpo-event-area start -->
    <div class="wpo-event-area-2 section-padding">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wpo-section-title">
              <span>Our Events</span>
              <h2>Upcoming Events</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 col-sm-6 col-12 custom-grid"
            v-for="event in events_data" :key="event.id">
            <div class="wpo-event-item">
              <div class="wpo-event-img">
                <div class="default-img">
                  <img :src="event.image" alt="">
                </div>
              </div>
              <div class="wpo-event-text">
                <h2>{{event.title}}</h2>
                <ul v-if="checkDate(event.start_date, event.end_date)">
                  <li>
                    <i class="fa fa-clock-o" aria-hidden="true"></i>
                    {{checkDate(event.start_date, event.end_date)}}
                  </li>
                </ul>
                <p>{{trimMyString(event.description, 65)}}</p>
                <a :href="event.link_url" target="_blank">{{event.link_text}}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="load_more">
          <div class="col-12 lode-more-wrapper">
            <button @click="loadMore" :disabled="load_more_disable">Load More</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import api from "../services/api";
    import moment from 'moment';

    export default {
        name: "events",
        data() {
            return {
                offset: 0,
                limit: 6,
                events_data: [],
                load_more: true,
                load_more_disable: false,
                modalImg: ''
            }
        },
        mounted() {
            window.scrollTo(0,0);
            this.getEvents(this.offset, this.limit)
        },
        methods: {
            loadMore() {
                this.offset = this.offset + this.limit;
                this.getEvents(this.offset, this.limit)
            },
            async getEvents(offset, limit) {
                this.load_more_disable = true;
                let more = await api.moreEvents(offset, limit);
                more = more.data;

                if (more.length < this.limit) {
                    this.load_more = false
                }
                more.forEach((value) => {
                    this.events_data.push(value)
                });
                for (const value of more) {
                    if (value.image !== null) {
                        let img = await api.getFiles(value.image);
                        if (img !== null) {
                            value.image = img.data['data']['full_url'];
                        }
                    }
                }
                this.load_more_disable = false
            },
            checkDate(start, end) {
                if (start && end === null) {
                    return moment(start).format('MM-DD-YYYY hh:mm')
                }
                if (start === null || end === null) {
                    return ''
                }
                if (moment(start).format('MM-DD-YYYY') === moment(end).format('MM-DD-YYYY')) {
                    return moment(start).format('MM-DD-YYYY') + ' | ' + moment(start).format('hh:mm') + ' - ' + moment(end).format('hh:mm')
                } else {
                    return moment(start).format('MM-DD-YYYY hh:mm') + ' - ' + moment(end).format('MM-DD-YYYY hh:mm')
                }
            },
            trimMyString (string, maxLength, start = 0) {
                if (string.length > maxLength) {
                    let trimmedString = string.substr(start, maxLength)
                    return (
                        trimmedString.substr(
                            start,
                            Math.min(trimmedString.length,   trimmedString.lastIndexOf(' '))
                        ) + ' ...'
                    )
                }
                return string
            },
        },
    }
</script>

<style scoped>
  .default-img {
    width: 100%;
    height: 291px;
    background-image: linear-gradient(to right, #f725845e, #7108b778);
  }

  .default-img img {
    height: 100%;
    object-fit: cover;
  }

  .lode-more-wrapper {
    display: flex;
    justify-content: center;
  }

  .lode-more-wrapper button {
    border-radius: 5px;
    display: inline-block;
    transition: all .3s;
    padding: 12px 20px;
    margin-top: 9px;
    font-size: 14px;
    font-weight: 600;
    background-color: #a14ec6;
    color: #fff;
    border: none;
  }
</style>
